<script setup lang="ts">
import { Alert, AlertWarningBody } from '@removify/ui/alert';
import { Button } from '@removify/ui/button';

import { AlertDialog } from '@/components/Global/Dialog';
import { useMobile } from '@/composables/mobile';

const emits = defineEmits<{
  close: [];
}>();
const message = import.meta.env.VITE_APP_EMERGENCY_MESSAGE;
const show = defineModel<boolean>('show', { default: true });
const { isMobile } = useMobile();

function onAlertClose() {
  show.value = false;
  emits('close');
}
</script>

<template>
  <template v-if="message">
    <template v-if="isMobile()">
      <AlertDialog
        v-model:open="show"
        title="Announcement"
        description="Announcement"
        :show-cancel="false"
      >
        <template #trigger>
          <span class="hidden"></span>
        </template>

        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="message"></div>

        <template #action>
          <Button @click="onAlertClose">
            Confirm
          </Button>
        </template>
      </AlertDialog>
    </template>

    <template v-else>
      <Transition>
        <Alert
          v-if="show"
          class="rounded-none bg-primary"
        >
          <AlertWarningBody @close="onAlertClose">
            <template #warning-icon>
              <IRmvAlertsBell class="size-5"></IRmvAlertsBell>
            </template>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="message"></div>
          </AlertWarningBody>
        </Alert>
      </Transition>
    </template>
  </template>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
