<script setup lang="ts">
import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogRoot,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@removify/ui/alert-dialog';
import { Button } from '@removify/ui/button';

const {
  triggerAsChild = true,
  title = '',
  description = '',
  type = 'default',
  actionLabel = 'Continue',
  cancelLabel = 'Cancel',
  showCancel = true,
} = defineProps<{
  triggerAsChild?: boolean;
  title?: string;
  description?: string;
  type?: 'default' | 'danger';
  actionLabel?: string;
  cancelLabel?: string;
  showCancel?: boolean;
}>();

const slots = defineSlots<{
  default(): any;
  trigger(): any;
  title(): any;
  cancel(): any;
  action(): any;
  description(): any;
}>();

// Require title and description
if ((!slots.title && !title) || (!slots.description && !description)) {
  const errorMessage = '[Removify UI]: You must pass in a title and description for alert-dialog';
  console.error(errorMessage);
  throw new Error(errorMessage);
}

const open = defineModel<boolean>('open', { default: false });
</script>

<template>
  <AlertDialogRoot v-model:open="open">
    <AlertDialogTrigger :as-child="triggerAsChild">
      <slot name="trigger">
        <Button>
          Show Dialog
        </Button>
      </slot>
    </AlertDialogTrigger>
    <!-- TODO: handle click outside -->
    <AlertDialogContent clean-style>
      <AlertDialogHeader
        class="h-14"
        :class="{
          'bg-danger': type === 'danger',
        }"
      >
        <div class="flex items-center justify-between gap-6">
          <AlertDialogTitle class="capitalize text-white">
            <slot name="title">
              {{ title }}
            </slot>
          </AlertDialogTitle>
          <IRmvInterfaceClose class="size-5 cursor-pointer bg-transparent text-white" @click="open = false" />
        </div>
        <AlertDialogDescription class="sr-only">
          <slot name="description">
            {{ description }}
          </slot>
        </AlertDialogDescription>
      </AlertDialogHeader>
      <div class="space-y-8 rounded-b bg-white p-4 pt-6">
        <div class="text-primary-950">
          <slot></slot>
        </div>
        <AlertDialogFooter>
          <slot name="cancel">
            <template v-if="showCancel">
              <AlertDialogCancel>
                {{ cancelLabel }}
              </AlertDialogCancel>
            </template>
          </slot>
          <slot name="action">
            <AlertDialogAction :color="type === 'danger' ? 'red' : 'primary'">
              {{ actionLabel }}
            </AlertDialogAction>
          </slot>
        </AlertDialogFooter>
      </div>
    </AlertDialogContent>
  </AlertDialogRoot>
</template>
