<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['modal']),
    clickOutside() {
      return this.modal.clickOutside;
    },
    outsideCloseMessage() {
      return this.modal.outsideCloseMessage;
    },
    showClose() {
      return this.modal.showClose;
    },
  },
  methods: {
    close() {
      // used on usa-gmb popup to set action after close
      if (this.modal.onCloseMutation) {
        this.$store.commit(this.modal.onCloseMutation);
      }
      this.$store.commit('modal/RESET_STATE');
    },
  },
};
</script>

<template>
  <modal
    v-model:show="modal.isVisible"
    :modal-classes="modal.classes"
    :modal-content-classes="modal.contentClasses"
    :size="modal.size"
    :body-classes="{ 'p-0': modal.gapless }"
    footer-classes="text-right"
    :click-outside="clickOutside"
    :outside-close-message="outsideCloseMessage"
    :show-close="showClose"
    :header-classes="modal.headerClasses"
    :headless="modal.headless"
    @close="close"
  >
    <template #header>
      <!-- eslint-disable vue/no-v-html -->
      <!-- We are the source of the content for this component (mostly) -->
      <!-- Although this is not a good way to handle html elements -->
      <!-- should drop the usage of vuex type model in the feature -->
      <div
        v-if="modal.htmlTitle"
        class="modal-title"
        v-html="modal.htmlTitle"
      ></div>
      <h2
        v-else-if="modal.title"
        class="modal-title font-bold"
        v-html="modal.title"
      ></h2>
    </template>
    <template v-if="modal.component">
      <component :is="modal.component"></component>
    </template>
    <template v-if="modal.content">
      <div class="modal-content">
        <div
          :class="{ 'with-progress': modal.showProgressBar }"
          v-html="modal.content"
        ></div>
        <div v-show="modal.showProgressBar" class="progress-bar"></div>
      </div>
    </template>
  </modal>
</template>

<style lang="scss" scoped>
@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}

.with-progress {
  position: relative;
  z-index: 1;
}

.progress-bar {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
  background-size: 40px 40px;
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  ) !important;
  background-color: $blue;
  animation: progress-bar-stripes 2s linear infinite;
  height: 5px;
  width: 200px;
  transform: translate(-50%, -50%);
  border-radius: 50px;
}
</style>
