import { useWindowSize } from '@vueuse/core';

/**
 * TODO:To be refactored
 * 1. Find a way to work with removify tailwind preset deps
 * 2. Maybe has to separate into two function because even if we only want a static value the reactive variables will be created
 * This is only for something really complex that's so hard to handle by css
 * Otherwise just use css
 */
export const useMobile = (widthBreakPoint = 768) => {
  const { width } = useWindowSize();

  const isMobileFromWidth = computed(() => {
    if (width.value <= widthBreakPoint) {
      return true;
    } else {
      return false;
    }
  });
  const isMobileComputed = computed(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      ) ||
      window.innerWidth <= 575
    ) {
      return true;
    } else {
      return false;
    }
  });

  const isMobile = () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      ) ||
      window.innerWidth <= 575
    ) {
      return true;
    } else {
      return false;
    }
  };
  return { isMobileComputed, isMobile, isMobileFromWidth };
};
