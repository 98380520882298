<script>
export default {
  name: 'BaseInput',
  inheritAttrs: false,
  props: {
    required: {
      type: Boolean,
      description: 'Whether input is required (adds an asterix *)',
    },
    group: {
      type: Boolean,
      description:
        'Whether input is an input group (manual override in special cases)',
    },
    alternative: {
      type: Boolean,
      description: 'Whether input is of alternative layout',
    },
    label: {
      type: String,
      description: 'Input label (text before input)',
    },
    error: {
      type: String,
      description: 'Input error (below input)',
    },
    successMessage: {
      type: String,
      description: 'Input success message',
      default: '',
    },
    labelClasses: {
      type: String,
      description: 'Input label css classes',
      default: 'form-control-label',
    },
    inputClasses: {
      type: String,
      description: 'Input css classes',
    },
    inputGroupClasses: {
      type: String,
      description: 'Input group css classes',
    },
    modelValue: {
      type: [String, Number, Boolean, Array],
      description: 'Input value',
    },
    type: {
      type: String,
      description: 'Input type',
      default: 'text',
    },
    appendIcon: {
      type: String,
      description: 'Append icon (right)',
    },
    prependIcon: {
      type: String,
      description: 'Prepend icon (left)',
    },
    rules: {
      type: [String, Array, Object],
      description: 'Vee validate validation rules',
      default: '',
    },
    name: {
      type: String,
      description: 'Input name (used for validation)',
      required: true,
    },
    limit: {
      type: Number,
      description: 'Character limit',
    },
    limitAtTop: {
      type: Boolean,
      description: 'Character limit position on top',
    },
    autogrow: {
      type: Boolean,
      default: false,
      description: 'Auto resize textarea',
    },
    autogrowMinHeight: {
      type: Number,
      default: 54,
      description: 'Minimum height of textarea (autogrow only)',
    },
    rows: {
      type: Number,
      default: 3,
      description: 'Number of rows (textarea only)',
    },
  },
  emits: ['update:modelValue', 'focus', 'blur', 'click:icon', 'change'],
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    listeners() {
      return {
        focus: this.onFocus,
        blur: this.onBlur,
        change: this.onChange,
      };
    },
    slotData() {
      return {
        focused: this.focused,
        error: this.error,
      };
    },
    hasIcon() {
      const { append, prepend } = this.$slots;
      return (
        append !== undefined ||
        prepend !== undefined ||
        this.appendIcon !== undefined ||
        this.prependIcon !== undefined ||
        this.group
      );
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    attrs() {
      const { id, class: className, ...rest } = this.$attrs;
      return {
        id,
        class: className,
        default: rest,
      };
    },
  },
  created() {
    this.$nextTick(() => {
      this.checkAutogrow();
    });
  },
  methods: {
    onInput() {
      this.checkAutogrow();
    },
    onFocus(evt) {
      this.focused = true;
      this.checkAutogrow();
      this.$emit('focus', evt);
    },
    onBlur(evt) {
      this.focused = false;
      this.$emit('blur', evt);
    },
    onChange() {
      this.$emit('change', this.modelValue);
    },
    onIconClicked() {
      this.$emit('click:icon');
    },
    isNotObject(val) {
      return typeof val !== 'object';
    },

    checkAutogrow() {
      if (this.autogrow) {
        if (this.$refs.input && this.$refs.input.value.length > 0) {
          this.$refs.input.style.height = `${this.autogrowMinHeight}px`;
          this.$refs.input.style.height =
            this.$refs.input.scrollHeight > this.autogrowMinHeight
              ? this.$refs.input.scrollHeight + 'px'
              : `${this.autogrowMinHeight}px`;
        } else {
          this.$refs.input.style.height = `${this.autogrowMinHeight}px`;
        }
      }
    },
  },
};
</script>

<template>
  <div :id="attrs.id" :class="attrs.class">
    <validation-provider
      v-slot="{ errors, meta: { valid, validated }, field }"
      v-model="value"
      :rules="rules"
      :name="name"
    >
      <div class="form-group">
        <slot name="label">
          <label v-if="label" :class="labelClasses">{{ label }}</label>
        </slot>
        <div
          class="inner"
          :class="[
            { 'input-group': hasIcon },
            { focused },
            { 'input-group-alternative': alternative },
            { 'has-label': label || $slots.label },
            inputGroupClasses,
          ]"
        >
          <div v-if="prependIcon || $slots.prepend" class="input-group-prepend">
            <span class="input-group-text">
              <slot name="prepend">
                <i :class="prependIcon"></i>
              </slot>
            </span>
          </div>
          <slot v-bind="slotData">
            <input
              v-if="type !== 'textarea'"
              v-bind="{ ...attrs.default, ...field }"
              :id="null"
              ref="input"
              :type="type"
              :valid="valid"
              :required="required"
              class="form-control"
              :class="[
                {
                  'is-valid': valid && validated && successMessage,
                },
                { 'is-invalid': !valid && validated },
                inputClasses,
              ]"
              v-on="listeners"
            />
            <textarea
              v-else
              v-bind="{ ...attrs.default, ...field }"
              ref="input"
              :type="type"
              :valid="valid"
              :required="required"
              :rows="rows"
              class="form-control"
              :class="[
                {
                  'is-valid': valid && validated && successMessage,
                },
                { 'is-invalid': !valid && validated },
                {
                  'auto-grow': autogrow,
                },
                inputClasses,
              ]"
              v-on="listeners"
              @input="onInput"
            />
          </slot>
          <div v-if="appendIcon || $slots.append" class="input-group-append" @click="onIconClicked">
            <span class="input-group-text">
              <slot name="append">
                <i :class="appendIcon"></i>
              </slot>
            </span>
          </div>
          <slot name="infoBlock"></slot>
          <span
            v-if="limit && isNotObject(value)"
            class="character-limit"
            :class="{
              'top': limitAtTop,
              'limit-exceeded': limit - value.length <= 0,
            }"
          >{{ limit - value.length }} / {{ limit }}</span>
        </div>
        <slot name="success">
          <div v-if="valid && validated" class="valid-feedback">
            {{ successMessage }}
          </div>
        </slot>
        <slot name="error">
          <div v-if="errors[0]" class="invalid-feedback" style="display: block">
            {{ errors[0] }}
          </div>
        </slot>
      </div>
    </validation-provider>
  </div>
</template>

<style lang="scss" scoped>
.auto-grow {
  resize: none;
  overflow: hidden;
  transition: all 100ms ease-in-out;
}
</style>
