<script setup lang="ts">
import { useStore } from 'vuex';

import { useSidebarStore } from './useSidebarStore';

defineOptions({
  name: 'Sidebar',
});

const props = withDefaults(
  defineProps<Props>(),
  {
    logo: '/img/logo/logo.svg',
    sidebarLinks: () => [],
    padding: false,
    backgroundColor: '',
    title: '',
    shortTitle: '',
  },
);
const {
  breakpoint,
  isMinimized,
  onMouseEnter,
  onMouseLeave,
  showSidebar,
  toggleMinimize,
} = useSidebarStore();
const store = useStore();

/**
 * Most of the props are not even used, maybe we should remove them
 */
interface Props {
  title?: string;
  shortTitle?: string;
  logo?: string;
  backgroundColor?:
    | ''
    | 'removify'
    | 'blue'
    | 'green'
    | 'orange'
    | 'red'
    | 'primary';
  sidebarLinks?: Array<any>;
  autoClose: boolean;
  padding?: boolean;
}

provide('autoClose', props.autoClose);

const SidebarItem = defineAsyncComponent(() => import('./SidebarItem.vue'));

const environment = computed(() => import.meta.env.VITE_APP_ENV);

const minimizeSidebar = () => {
  toggleMinimize();
  store.commit('SET_MENU', !isMinimized);
};

onMounted(() => {
  isMinimized.value =
    breakpoint.value < window.innerWidth && store.state.toggleMenu;
  minimizeSidebar();
});

onBeforeUnmount(() => {
  if (showSidebar.value) {
    showSidebar.value = false;
  }
});
</script>

<template>
  <div
    class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-dark bg-legacy-default"
    @mouseenter="onMouseEnter()"
    @mouseleave="onMouseLeave()"
  >
    <div class="scrollbar-inner">
      <div class="sidenav-header flex items-center">
        <span class="navbar-brand">
          <img :src="logo" class="navbar-brand-img" alt="Sidebar logo" />
          <span v-if="environment !== 'production'" class="env">{{
            environment.slice(0, 3).toUpperCase()
          }}</span>
        </span>
        <div class="ml-auto">
          <!-- Sidenav toggler -->
          <div
            class="sidenav-toggler flex items-center"
            :class="{ active: !isMinimized }"
            @click="minimizeSidebar"
          >
            <i
              class="ri ri-minimise-side ri-125x sidenav-toggler-icon text-white"
            ></i>
          </div>
        </div>
      </div>
      <slot></slot>
      <div class="navbar-inner" :class="{ 'py-6': padding }">
        <ul class="navbar-nav">
          <slot name="links">
            <SidebarItem
              v-for="(link, index) in sidebarLinks"
              :key="link.name + index"
              :link="link"
              :children-length="link.children?.length ?? 0"
            >
              <SidebarItem
                v-for="(subLink, i) in link.children"
                :key="subLink.name + i"
                :link="subLink"
                :children-length="subLink.children?.length ?? 0"
              ></SidebarItem>
            </SidebarItem>
          </slot>
        </ul>
        <slot name="links-after"></slot>
        <!-- .bottom-fixed -->
        <ul class="navbar-nav mt-6">
          <slot name="bottomLinks">
            <SidebarItem
              v-for="(link, index) in sidebarLinks"
              :key="link.name + index"
              :link="link"
              :children-length="link.children?.length ?? 0"
            >
              <SidebarItem
                v-for="(subLink, i) in link.children"
                :key="subLink.name + i"
                :link="subLink"
                :children-length="subLink.children?.length ?? 0"
              ></SidebarItem>
            </SidebarItem>
          </slot>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.env {
  font-size: 12px;
  font-weight: 700;
  position: absolute;
  bottom: -20px;
  display: block;
  text-align: center;
  width: 6em;
  background-color: $orange;
  border-radius: 2em;
  padding: 0.2em 0.5em;
  text-transform: uppercase;
}
</style>
