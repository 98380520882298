<script setup lang="ts">
import type { AcceptedCurrency } from '@/types/currency';

interface Props {
  /**
   * Currently we only support AUD, USD, EUR and GBP
   */
  currency: AcceptedCurrency;
  /**
   * The amount to display
   */
  amount: number;
}

defineOptions({
  name: 'BaseMoney',
});

const props = defineProps<Props>();
const currencyLower = props.currency.toLowerCase();

const formatNumber = (number: number) => {
  return new Intl.NumberFormat('en-AU', { minimumFractionDigits: 2 }).format(number);
};

const currencySymbol = computed(() => {
  switch (currencyLower) {
    case 'aud':
      return '$';
    case 'usd':
      return '$';
    case 'gbp':
      return '£';
    case 'eur':
      return '€';
    case 'cad':
      return '$';
    default:
      return '';
  }
});

const showCurrency = computed(() => {
  // Only show currency if it's cad, aud and usd
  switch (currencyLower) {
    case 'aud':
    case 'usd':
    case 'cad':
      return true;
    case 'gbp':
    case 'eur':
    default:
      return false;
  }
});
</script>

<template>
  <span>
    {{ currencySymbol }}<span class="tabular-nums">{{ formatNumber(amount) }}</span> <span v-if="showCurrency" class="self-center text-[.75em] uppercase">{{ currency }}</span>
  </span>
</template>
