<script>
export default {
  name: 'BaseDropdown',
  props: {
    tag: {
      type: String,
      default: 'div',
      description: 'Dropdown html tag (e.g div, ul etc)',
    },
    titleTag: {
      type: String,
      default: 'button',
      description: 'Dropdown title (toggle) html tag',
    },
    dropdownTag: {
      type: String,
      default: 'ul',
      description: 'Dropdown title (toggle) html tag',
    },
    title: {
      type: String,
      description: 'Dropdown title',
    },
    direction: {
      type: String,
      default: 'down', // up | down
      description: 'Dropdown menu direction (up|down)',
    },
    icon: {
      type: String,
      description: 'Dropdown icon',
    },
    titleClasses: {
      type: [String, Object, Array],
      description: 'Title css classes',
    },
    menuClasses: {
      type: [String, Object],
      description: 'Menu css classes',
    },
    menuOnRight: {
      type: Boolean,
      description: 'Whether menu should appear on the right',
    },
    hasToggle: {
      type: Boolean,
      description: 'Whether dropdown has arrow icon shown',
      default: true,
    },
    closeAfterClick: {
      type: Boolean,
      description: 'Whether dropdown closes after click',
      default: false,
    },
  },
  emits: ['change'],
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleDropDown() {
      if (this.closeAfterClick) this.isOpen = !this.isOpen;
      else this.isOpen = true;
      this.$emit('change', this.isOpen);
    },
    closeDropDown() {
      this.isOpen = false;
      this.$emit('change', false);
    },
  },
};
</script>

<template>
  <component
    :is="tag"
    v-click-outside="closeDropDown"
    :class="[{ show: isOpen }, `drop${direction}`]"
    @click="toggleDropDown"
  >
    <slot name="title-container" :is-open="isOpen">
      <component
        :is="titleTag"
        class="btn-rotate"
        :class="[{ 'dropdown-toggle': hasToggle }, titleClasses]"
        :aria-expanded="isOpen"
        data-toggle="dropdown"
      >
        <slot name="title" :is-open="isOpen">
          <span v-if="icon" class="btn-inner--icon">
            <i :class="icon"></i>
          </span>
          {{ title }}
        </slot>
      </component>
    </slot>
    <component
      :is="dropdownTag"
      class="dropdown-menu"
      :class="[
        { show: isOpen },
        { 'dropdown-menu-right': menuOnRight },
        menuClasses,
      ]"
    >
      <slot></slot>
    </component>
  </component>
</template>
