<script>
export default {
  name: 'BasePagination',
  props: {
    /**
     * @deprecated pageCount is deprecated. Use total and perPage instead
     */
    pageCount: {
      type: Number,
      description:
        'Pagination page count. This should be specified in combination with perPage',
    },
    perPage: {
      type: Number,
      default: 10,
      description:
        'Pagination per page. Should be specified with total or pageCount',
    },
    total: {
      type: Number,
      default: 0,
      description:
        'Can be specified instead of pageCount. The page count in this case will be total/perPage',
    },
    value: {
      type: Number,
      default: 1,
      description: 'Pagination value',
    },
    size: {
      type: String,
      default: '',
      description: 'Pagination size',
    },
    align: {
      type: String,
      default: '',
      description: 'Pagination alignment (e.g center|start|end)',
    },
  },
  data() {
    return {
      defaultPagesToDisplay: 5,
    };
  },
  computed: {
    totalPages() {
      if (this.pageCount > 0) return this.pageCount;
      if (this.total > 0) {
        return Math.ceil(this.total / this.perPage);
      }
      return 1;
    },
    pagesToDisplay() {
      if (this.totalPages > 0 && this.totalPages < this.defaultPagesToDisplay) {
        return this.totalPages;
      }
      return this.defaultPagesToDisplay;
    },
    minPage() {
      if (this.value >= this.pagesToDisplay) {
        const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
        const newMaxPage = pagesToAdd + this.value;
        if (newMaxPage > this.totalPages) {
          return this.totalPages - this.pagesToDisplay + 1;
        }
        return this.value - pagesToAdd;
      } else {
        return 1;
      }
    },
    maxPage() {
      if (this.value >= this.pagesToDisplay) {
        const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
        const newMaxPage = pagesToAdd + this.value;
        if (newMaxPage < this.totalPages) {
          return newMaxPage;
        } else {
          return this.totalPages;
        }
      } else {
        return this.pagesToDisplay;
      }
    },
    nextPage() {
      if (this.value < this.totalPages) {
        return this.value + 1;
      }
      return this.value;
    },
    prevPage() {
      if (this.value > 1) {
        return this.value - 1;
      }
      return this.value;
    },
  },
  watch: {
    value: {
      handler() {
        window.scrollTo(0, 0); // scroll to top on pagination
      },
    },
  },
  created() {
    if (this.pageCount !== undefined) {
      console.warn(
        '[BasePagination] The "pageCount" prop is deprecated. Please use "total" and "prePage" instead.',
      );
    }
  },
  methods: {
    range(min, max) {
      const arr = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },
  },
};
</script>

<template>
  <ul
    class="pagination"
    :class="[size && `pagination-${size}`, align && `justify-content-${align}`]"
  >
    <li class="page-item prev-page" :class="{ disabled: value === 1 }">
      <router-link
        :to="{ query: { ...$route.query, page: prevPage } }"
        class="page-link rounded"
        aria-label="Previous"
      >
        <span aria-hidden="true"><i class="ri ri-back text-legacy-default" aria-hidden="true"></i></span>
      </router-link>
    </li>

    <!-- if the current page does not have 1, show button to go first page -->
    <div v-if="minPage !== 1" class="relative flex">
      <li class="page-item ml-2" :class="{ active: value === 1 }">
        <router-link
          :to="{ query: { ...$route.query, page: 1 } }"
          class="page-link link-default rounded"
        >
          1
        </router-link>
      </li>
      <div class="ml-2 flex">
        ...
      </div>
    </div>

    <li
      v-for="item in range(minPage, maxPage)"
      :key="item"
      class="page-item ml-2"
      :class="{ active: value === item }"
    >
      <router-link
        :to="{ query: { ...$route.query, page: item } }"
        class="page-link link-default rounded"
      >
        {{ item }}
      </router-link>
    </li>

    <!-- if the current page does not have the last page , show button to go last page -->
    <div v-if="maxPage !== totalPages" class="relative flex">
      <div class="ml-2 flex">
        ...
      </div>
      <li class="page-item ml-2" :class="{ active: value === totalPages }">
        <router-link
          :to="{ query: { ...$route.query, page: totalPages } }"
          class="page-link link-default rounded"
        >
          {{ totalPages }}
        </router-link>
      </li>
    </div>

    <li
      class="page-item next-page ml-2"
      :class="{ disabled: value === totalPages }"
    >
      <router-link
        :to="{ query: { ...$route.query, page: nextPage } }"
        class="page-link rounded"
        aria-label="Next"
      >
        <span aria-hidden="true"><i class="ri ri-forward text-legacy-default" aria-hidden="true"></i></span>
      </router-link>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
.link-default {
  &:hover {
    color: $default;
  }
  color: $default;
}
</style>
